import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/Devfest2024/Devfest2024/src/layout/mdx.tsx";
export const metadata = {
  title: "Code of Conduct"
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PrimarySection = makeShortcode("PrimarySection");
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PrimarySection mdxType="PrimarySection">
      <p>{`The GDG Nantes (Organisers of DevFest Nantes) is proud to be an open community that respects differences and diversity. We want all attendees (attendees, speakers, …) to have an enjoyable and fulfilling experience. This implies that all attendees, speakers, sponsors and volunteers should respect this code of conduct.`}</p>
      <h2>{`Light version of explicit “Code of Conduct policy”`}</h2>
      <p>{`DevFest Nantes is dedicated to providing a harassment-free conference experience for everyone, regardless of:`}</p>
      <ul>
        <li parentName="ul">{`gender`}</li>
        <li parentName="ul">{`gender identity and expression`}</li>
        <li parentName="ul">{`sexual orientation`}</li>
        <li parentName="ul">{`disability`}</li>
        <li parentName="ul">{`physical appearance`}</li>
        <li parentName="ul">{`body size`}</li>
        <li parentName="ul">{`race`}</li>
        <li parentName="ul">{`age`}</li>
        <li parentName="ul">{`religion`}</li>
        <li parentName="ul">{`technology choices`}</li>
      </ul>
      <p>{`We do not tolerate harassment of conference participants in any form. Sexual language and imagery is not appropriate for any conference venue, including talks, codelabs, animations, parties, social networks. Conference participants violating these rules may be sanctioned or expelled from the conference without a refund at the discretion of the conference organizers.`}</p>
      <h2>{`Longer version`}</h2>
      <p>{`DevFest Nantes is dedicated to providing a harassment-free conference experience for everyone, regardless of:`}</p>
      <ul>
        <li parentName="ul">{`gender`}</li>
        <li parentName="ul">{`gender identity and expression`}</li>
        <li parentName="ul">{`sexual orientation`}</li>
        <li parentName="ul">{`disability`}</li>
        <li parentName="ul">{`physical appearance`}</li>
        <li parentName="ul">{`body size`}</li>
        <li parentName="ul">{`race`}</li>
        <li parentName="ul">{`age`}</li>
        <li parentName="ul">{`religion`}</li>
        <li parentName="ul">{`technology choices`}</li>
      </ul>
      <p>{`We do not tolerate harassment of conference participants in any form. Sexual language and imagery is not appropriate for any conference venue, including talks, codelabs, animations, parties, social networks. Conference participants violating these rules may be sanctioned or expelled from the conference without a refund at the discretion of the conference organizers.`}</p>
      <p>{`Harassment includes, but is not limited to:`}</p>
      <ul>
        <li parentName="ul">{`Verbal comments that reinforce social structures of domination, related to gender, gender identity and expression, sexual orientation, disability, physical appearance, body size, race, age, religion, technology choices.`}</li>
        <li parentName="ul">{`Sexual images in public spaces`}</li>
        <li parentName="ul">{`Deliberate intimidation, stalking, or following`}</li>
        <li parentName="ul">{`Harassing photography or recording`}</li>
        <li parentName="ul">{`Sustained disruption of talks or other events`}</li>
        <li parentName="ul">{`Inappropriate physical contact`}</li>
        <li parentName="ul">{`Unwelcome sexual attention`}</li>
        <li parentName="ul">{`Nudity in public spaces`}</li>
        <li parentName="ul">{`Advocating for, or encouraging, any of the above behaviour`}</li>
      </ul>
      <p>{`Participants asked to stop any harassing behavior are expected to comply immediately`}</p>
      <p>{`Sponsors in the expo hall, or similar activities are also subject to the anti-harassment policy. Sponsors should not use sexualized images, activities, or other material. Booth staff (including volunteers) should not use sexualized clothing/uniforms/costumes, or otherwise create a sexualized environment. A booth could be closed in case of non respect of these rules.`}</p>
      <p>{`If a participant engages in harassing behaviour, event organisers retain the right to take any actions to keep the event a welcoming environment for all participants. This includes warning the offender or expulsion from the conference with no refund.`}</p>
      <p>{`Event organisers may take action to redress anything designed to, or with the clear impact of, disrupting the event or making the environment hostile for any participants.`}</p>
      <p>{`We expect participants to follow these rules at all event venues and event-related social activities (slack, twitter, mailing, …). We think people should follow these rules outside event activities too!`}</p>
      <h2>{`Need to report`}</h2>
      <p>{`If someone bothers you in any way, we request you to immediately contact the organizers who will put in place the necessary measures. We want to keep the DevFest respectful of others and make it a socially enjoyable event.`}</p>
      <p>{`Trained conference staff can be identified by "DevFest Safe" or "Shadow Master" t-shirts.`}</p>
      <img src="/images/tshirt-devfestsafe-and-orga.png" height="300px" />
      <p>{`A "DevFest Safe" booth will also be set up in the lounge area to provide you with information and welcome you if you need it. This booth will be displayed on the map available on your badge. Harassment and other code of conduct violations reduce the value of our event for everyone. We want you to be happy at our event. People like you make our event a better place.`}</p>
      <p>{`You can make a report personally.`}</p>
      <p><strong parentName="p">{`Personal Report`}</strong></p>
      <p>{`You can make a personal report by:`}</p>
      <p>{`Contacting a staff member, identified by "DevFest Safe" or "Shadow Master" t-shirt.`}</p>
      <p>{`When taking a personal report, our staff will ensure you are safe and cannot be overheard. They may involve other event staff to ensure your report is managed properly. Once safe, we’ll ask you to tell us about what happened. This can be upsetting, but we’ll handle it as respectfully as possible, and you can bring someone to support you. You won’t be asked to confront anyone and we won’t tell anyone who you are.`}</p>
      <p>{`Our team will be happy to help you contact hotel/venue security, local law enforcement, local support services, provide escorts, or otherwise assist you to feel safe for the duration of the event. We value your attendance.`}</p>
      <p><strong parentName="p">{`Report Data`}</strong></p>
      <p>{`If you make a report via email, please include:`}</p>
      <ul>
        <li parentName="ul">{`The title format : `}{`[DevFest Nantes]`}{` COC Violation`}</li>
        <li parentName="ul">{`Your contact info (so we can get in touch with you if we need to follow up)`}</li>
        <li parentName="ul">{`Date and time of the incident`}</li>
        <li parentName="ul">{`Location of incident`}</li>
        <li parentName="ul">{`Whether the incident is ongoing`}</li>
        <li parentName="ul">{`Description of the incident`}</li>
        <li parentName="ul">{`Identifying information of the reported person: name, physical appearance, height, clothing, voice accent, `}{`*`}{` identifying badge information - such as company name, ribbons, or badge number`}</li>
        <li parentName="ul">{`Additional circumstances surrounding the incident`}</li>
        <li parentName="ul">{`Other people involved in or witnesses to the incident and their contact information or description`}</li>
      </ul>
      <p><strong parentName="p">{`Confidentiality`}</strong></p>
      <p>{`All reports will be kept confidential. When we discuss incidents with people who are reported, we will anonymize details as much as we can to protect reporter privacy.`}</p>
      <p>{`However, some incidents happen in one-on-one interactions, and even if the details are anonymized, the reported person may be able to guess who made the report. If you have concerns about retaliation or your personal safety, please note those in your report. We still encourage you to report, so that we can support you while keeping our conference attendees safe. In some cases, we can compile several anonymized reports into a pattern of behavior, and take action on that pattern.`}</p>
      <p>{`In some cases we may determine that a public statement will need to be made. If that’s the case, the identities of all victims and reporters will remain confidential unless those individuals instruct us otherwise.`}</p>
      <p><strong parentName="p">{`Contacts`}</strong></p>
      <p>{`In case of a conflict of interest, you can individually contact:`}</p>
      <ul>
        <li parentName="ul">{`Team DevFest Safe:  +33 7 70 24 53 64`}</li>
        <li parentName="ul">{`Audrey Le Mercier`}
          <ul parentName="li">
            <li parentName="ul">{`In charge of diversity and speakers relation`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "mailto:audrey@gdgnantes.com"
              }}>{`audrey@gdgnantes.com`}</a></li>
          </ul>
        </li>
        <li parentName="ul">{`Jean-François Garreau`}
          <ul parentName="li">
            <li parentName="ul">{`Co-founder of DevFest`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "mailto:jef@gdgnantes.com"
              }}>{`jef@gdgnantes.com`}</a></li>
          </ul>
        </li>
        <li parentName="ul">{`The DevFestNantes Twitter account by dm.`}</li>
      </ul>
      <h2>{`License`}</h2>
      <p>{`This Code of Conduct was forked from the example policy from the Geek Feminism wiki, created by the Ada Initiative and other volunteers, which is under a Creative Commons Zero license.`}</p>
    </PrimarySection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      